<template>
    <main>
        <page-header-compact>Configuración - Secciones Aiepi</page-header-compact>
        <div class="container-xl px-4 mt-n10">
            <div class="card">
                <div class="card-body">
                    <div class="col-12 d-flex justify-content-end py-4">
                        <button @click="modificar=false; abrirModal();" type="button" class="btn btn-primary ">
                            Crear Seccion&nbsp;<i class="fas fa-plus"></i>
                        </button>
                    </div>
                    <div class="modal fade" id="modalAiepi" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{{tituloModal}}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form >
                                        <div class="form-group">
                                            <label class="form-label">Codigo</label>
                                            <input type="text" class="form-control" v-model="seccion.codigo" placeholder="Codigo" :class="{'is-invalid':$v.seccion.codigo.$error && $v.seccion.codigo.$dirty}">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Nombre</label>
                                            <input type="text" class="form-control" v-model="seccion.nombre" placeholder="Nombre" :class="{'is-invalid':$v.seccion.nombre.$error && $v.seccion.nombre.$dirty}">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Multiples Diagnosticos</label>
                                            <select  v-model="seccion.multiple_diagnostico"   class="form-control" :class="{'is-invalid':$v.seccion.multiple_diagnostico.$error && $v.seccion.multiple_diagnostico.$dirty}" >
                                                <option value="S">SI</option>
                                                <option value="N">NO</option>
                                            </select>
                                        </div>
                                        <div class="card-header bg-light ">Posibles DIagnosticos</div>
                                        <div class="card-body">
                                            <div class="form-group row" >
                                                <div class="col-8">
                                                    <label class="form-label">Diagnostico</label>
                                                    <input type="text" class="form-control" v-model="diag.diagnostico" :class="{'is-invalid':$v.diag.diagnostico.$error && $v.diag.diagnostico.$dirty}" >
                                                </div>
                                                <div class="col-4 mt-4 py-2">
                                                    <button class="btn btn-primary " @click.prevent="agregarDiagnostico();" type="button">Agregar Diagnostico&nbsp;<i class="fa fa-plus"></i></button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <table class="table table-striped table-sm ">
                                                    <tr class="table-primary">
                                                        <th>Diagnostico</th>
                                                        <th></th>
                                                    </tr>
                                                    <tr v-for="(item,i) in seccion.diagnosticos" :key="i">
                                                        <td>{{item.diagnostico}}</td>
                                                        <td>
                                                            <button class="btn btn-outline-danger"  @click.prevent="removerOpcion()">
                                                                <i class="fas fa-trash-alt"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>


                                        <div class="">
                                            <div class="card-header bg-light ">Edades</div>
                                            <div class="card-body">
                                                <div class="form-group row">
                                                    <div class="col-3">
                                                        <label class="form-label">Edad Inicio</label>
                                                        <input type="number" class="form-control" v-model="edad.edad_inicio" :class="{'is-invalid':$v.edad.edad_inicio.$error && $v.edad.edad_inicio.$dirty}">
                                                    </div>
                                                    <div class="col-3">
                                                        <label class="form-label">Edad Final</label>
                                                        <input type="number" class="form-control"  v-model="edad.edad_final" :class="{'is-invalid':$v.edad.edad_final.$error && $v.edad.edad_final.$dirty}">
                                                    </div>
                                                    <div class="col-3">
                                                        <label class="form-label">Tipo Edad</label>
                                                        <select  v-model="edad.tipo_edad"   class="form-control" :class="{'is-invalid':$v.edad.tipo_edad.$error && $v.edad.tipo_edad.$dirty}" >
                                                            <option value="A">AÑOS</option>
                                                            <option value="M">MESES</option>
                                                            <option value="D">DIAS</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-3 mt-4 py-2">
                                                        <button class="btn btn-primary " @click.prevent="agregarEdad();" type="button">Agregar Edad&nbsp;<i class="fa fa-plus"></i></button>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <table class="table table-striped table-sm">
                                                        <tr class="table-primary">
                                                            <th>Edad Inicio</th>
                                                            <th>Edad Final</th>
                                                            <th>Tipo Edad</th>
                                                            <th></th>
                                                        </tr>
                                                        <tr v-for="(item,i) in seccion.edades" :key="i">
                                                            <td>{{item.edad_inicio}}</td>
                                                            <td>{{item.edad_final}}</td>
                                                            <td>{{item.tipo_edad}}</td>
                                                            <td>
                                                                <button class="btn btn-outline-danger"  @click.prevent="removerEdad()">
                                                                    <i class="fas fa-trash-alt"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-success" @click="guardar()">
                                        <i class="fas fa-save"></i>&nbsp;Guardar
                                     </button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table table-responsive">
                        <table class="table table-striped table-bordered">
                            <tr class="table-primary">
                                <th>Codigo</th>
                                <th>Nombre</th>
                                <th>Edades</th>
                                <th>Diagnosticos</th>
                                <th>Opciones</th>
                            </tr>
                            <tr v-for="item in secciones" :key="item.id">
                                <td>{{item.codigo}}</td>
                                <td>{{item.nombre}}</td>
                                <td v-for="ite in item.aiepi_edad" :key="ite.id">{{ite.edad_inicio}} - {{ite.edad_final}} {{ite.tipo_edad}}</td>
                                <td class="table-warning"><span  v-for="diag in item.aiepi_diagnostico" :key="diag.id" ><li>{{diag.diagnostico}}</li></span></td>
                                <td >
                                   <div class="form-row">
                                        <div class="form-group px-2">
                                            <button @click="modificar=true; abrirModal(item);" class="btn btn-outline-primary lift" >
                                                <i class="fa fa-edit"></i>&nbsp;Editar
                                            </button>
                                        </div>
                                        <div class="form-group px-2">
                                            <router-link
                                                :to="{ name: 'items.secciones', params: { id: item.id } }"
                                                class="btn btn-outline-warning "
                                                >
                                               <i class="fas fa-check fa-fw"></i>  Items
                                            </router-link>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../../../components/layouts/content/page-header-compact";
import seccionAiepiService from '../../../../../services/seccionAiepiService';

import 'bootstrap';
import $ from 'jquery';
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";
import edadAiepiService from '../../../../../services/edadAiepiService';
import diagnosticoAiepiService from '../../../../../services/diagnosticoAiepiService';



export default {
    components: {PageHeaderCompact},
    data(){
        return{
            modificar:true,
            tituloModal:'',
            seccion:{
                id:'',
                codigo:'',
                nombre:'',
                multiple_diagnostico:'',
                edades:[],
                diagnosticos:[],
            },
            secciones:{},
            edad:{
                edad_inicio:'',
                edad_final:'',
                tipo_edad:'',
            },
            diag:{
                diagnostico:'',
            },

        }
    },
    validations (){
        return {
            seccion:{
                codigo:{required},
                nombre:{required},
                multiple_diagnostico:{required},
            },
            edad:{
                edad_inicio:{required},
                edad_final:{required},
                tipo_edad:{required},
            },
            diag:{
                diagnostico:{required},
            }
        }
    },
    methods: {
        async abrirModal(data={}){
            $('#modalAiepi').modal('show');
            if(this.modificar){
                this.seccion.id=data.id;
                this.tituloModal="Modificar Seccion";
                this.seccion.codigo=data.codigo;
                this.seccion.nombre=data.nombre;
                this.seccion.multiple_diagnostico=data.multiple_diagnostico;
                const response=await edadAiepiService.showBySec(this.seccion.id);
                this.seccion.edades = response.data;
                const res=await diagnosticoAiepiService.showBySec(this.seccion.id);
                this.seccion.diagnosticos = res.data;
            }else{
                this.id=0;
                this.tituloModal="Crear Seccion";
                this.seccion.codigo='';
                this.seccion.nombre='';
                this.seccion.multiple_diagnostico='';
                this.seccion.edades=[];
                this.seccion.diagnosticos=[];
            }
        },
        async cargarSecciones(){
            const response = await seccionAiepiService.index();
            this.secciones=response.data;
        },
        async guardar(){
            this.$v.seccion.$touch();
            if(this.$v.seccion.$invalid){
                return
            }

            if(this.modificar){
                this.LoaderSpinnerShow();
                await seccionAiepiService.update(this.seccion);
                this.LoaderSpinnerHide();
                Swal.fire('Datos actualizados con exito','', 'success');
                $('#modalAiepi').modal('hide');
                this.cargarSecciones();

            }else{
                this.LoaderSpinnerShow();
                await seccionAiepiService.store(this.seccion);
                this.LoaderSpinnerHide();
                Swal.fire('Datos guardados con exito','', 'success');
                $('#modalAiepi').modal('hide');
                this.cargarSecciones();
            }

        },
       agregarEdad(){
            this.$v.edad.$touch();
            if (this.$v.edad.$invalid) {
                return;
            }
            this.seccion.edades.push({...this.edad});
            this.edad={};
        },
        agregarDiagnostico(){
            this.$v.diag.$touch();
            if (this.$v.diag.$invalid) {
                return;
            }
            this.seccion.diagnosticos.push({...this.diag});
            this.diag={};
        },
        removerOpcion(index) {
            this.seccion.diagnosticos.splice(index, 1);
        },
        removerEdad(index) {
            this.seccion.edades.splice(index, 1);
        },

        limpiarCampos(){
            this.edad={};
            this.diagnostico={}
        },
    },
    created(){
        this.cargarSecciones();
    }
}
</script>

