import Service from "./Service";
const baseurl = '/api/historia-diagnostico-aiepi';
export default {
    index() {
        return Service.get(`${baseurl}/`);
    },
    showBySec(id) {
        return Service.get(`${baseurl}/show-by-sec/${id}`);
    },
    
}